import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import {buildBreadcrumbsItem} from '../helpers'
import styles from '../scss/page.scss'
import Loader from "../components/loader/loader";

class SharePage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Honest Paws Referral Program'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'When you refer a friend to Honest Paws, not only do they save $20 on their first order, but you get $20 of Honest Paws credit. Click here to refer a friend today.'
      },
      children: []
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('Referral Program', this.props.location.href)
    ]
    this.loaderTitle = ["While you wait, here is a pun... What do you call a dog magician? A ", <b>labrabacadadbrador!</b>]
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    !function(d,s,id){
      var js,fjs=d.getElementsByTagName(s)[0];
      if(!d.getElementById(id)){
        js=d.createElement(s);
        js.id=id;
        js.src="//portal.referralcandy.com/assets/widgets/refcandy-candyjar.js";
        fjs.parentNode.insertBefore(js,fjs);
      }
    }
    (document,"script","refcandy-candyjar-js");
  }

  endLoad() {
    this.setState({
      isLoading: false
    })
  };

  render() {
    return (
      <Layout containerClassName={`page page-share`} breadcrumbs={ this.breadcrumbs } location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Loader style={ {display: this.state.isLoading ? 'block' : 'none'} } loadertitle={this.loaderTitle}/>
        <section className="bg-white share-referral-section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="text-primary">Honest Paws Referral Program</h1>
                <iframe id="refcandy-candyjar" data-id="s45qask9j3emcvm1ix0yyzo0r" data-height="1300" data-width="100%" frameBorder="0" onLoad={() => this.endLoad()}></iframe>
            </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SharePage

export const SharePageQuery = graphql`
  query SharePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import styles from './loader.scss'

function Loader(props) {
  return (
    <div className="loader" { ...props }>
      <div className="d-flex justify-content-center align-items-center flex-column h-100">
        <Spinner className="text-success" animation="border" role="status" size='lg' alt="Loading Indicator"></Spinner>
        <div className="col-8 mx-auto mt-3 text-primary">{props.loadertitle}</div>
      </div>
    </div>)
}

export default Loader;